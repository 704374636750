import React from 'react'
import { Spin } from 'antd';

export default function Loading({height=500}) {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
            
            margin: '20px 0',
            marginBottom: '20px',
            padding: '30px 50px',
            textAlign: 'center',
            backgroundColor: 'white',
            // background: 'rgba(0, 0, 0, 0.05)',
            borderRadius: '4px',
        }}>
            <Spin size={60}/>
        </div >
    )
}

export function LoadingMini(){
     return (
        <div style={{
            margin: '20px 0',
            marginBottom: '20px',
            padding: '30px 50px',
            textAlign: 'center',
            backgroundColor: 'white',
            height: 500,
            // background: 'rgba(0, 0, 0, 0.05)',
            borderRadius: '4px',
        }}>
            <Spin />
        </div >
    )
}
