import React from 'react'
import { Avatar, Button, Tooltip } from 'antd';
import { FormOutlined, UserOutlined, MailOutlined, MobileOutlined, BookOutlined } from '@ant-design/icons';
// import DetallesFisioModal from './detalles.fisio.modal';

export default function DetallesPaciente({ paciente, setIsEditModalOpen }) {


    return (<div style={{ marginTop: 16, padding: 10, display: 'flex', flexDirection: 'column', backgroundColor: 'white', width: '50%', borderRadius: 8 }}>

        <div style={{ marginTop: 8, flexDirection: 'column', textAlign: 'center' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                {
                    paciente.avatar && paciente.avatar.length > 9 ?
                        <img width={82} src={'https://api.recreamed.com/images/' + paciente.avatar} alt='ProfilePic' /> :
                        <Avatar size={82} icon={<UserOutlined />} className='btnIconCentered' />
                }
            </div>

            <div style={{ height: 6 }}></div>
            <div >
                <span style={{ fontSize: 10, fontWeight: 600 }}>Nombre del px</span>
                <p className='nombre'>{paciente.name}</p>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
            <div >
                <span style={{ fontSize: 10, fontWeight: 600 }}>Correo</span>
                <p className='datos'>{paciente.email}</p>
            </div>

            <div>
                <span style={{ fontSize: 10, fontWeight: 600 }}>Teléfono</span>
                <p className='datos'>{paciente.telefono}</p>
            </div>
            {/* </div> */}


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <div >
                    <span style={{ fontSize: 10, fontWeight: 600 }}>Fecha de nacimiento</span>
                    <p className='datos'>{paciente.fecha_nacimiento ? new Date(paciente.fecha_nacimiento).toLocaleDateString() : "-"}</p>
                </div>
                <div>
                    <span style={{ fontSize: 10, fontWeight: 600 }}>No. Expediente</span>
                    <p className='datos'>{paciente._id.substring(19).toUpperCase()}</p>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                {/* <span style={{ fontSize: 10, fontWeight: 600 }}>Responsable</span> */}

                {
                    paciente.responsable.nombre && <div >
                        <span style={{ fontSize: 10, fontWeight: 600 }}>Nombre del responsable</span>
                        <p className='datos'>{paciente.responsable.nombre}</p>
                    </div>
                }
                {
                    paciente.responsable.telefono && <div >
                        <span style={{ fontSize: 10, fontWeight: 600 }}>Teléfono del responsable</span>
                        <p className='datos'>{paciente.responsable.telefono}</p>
                    </div>
                }
            </div>

            {/* {
                TYPE === 'fisio' && <DetallesFisioModal userData={paciente} />
            } */}
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', paddingRight: 16 }}>
            <Tooltip title="Editar datos del paciente">
                <Button className='btnIconCentered' onClick={() => setIsEditModalOpen(true)} size='small' type="primary" shape="circle" icon={<FormOutlined className='sizedIcon' />} ghost />
            </Tooltip>
        </div>


    </div>)
}
